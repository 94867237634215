import React from 'react'
import classnames from '@helpers/misc/classnames'

export default function AddressCard({
  children,
  shadow = true
}: {
  children: React.ReactNode
  shadow?: boolean
}) {
  return (
    <div
      className={classnames('px-4 py-5 bg-white dark:bg-zinc-800', {
        'shadow-sm border-gray-200 shadow-gray-200 dark:shadow-zinc-700 dark:border-zinc-700':
          shadow
      })}>
      {children}
    </div>
  )
}
