import {useEffect, useRef, useState} from 'react'
import Loading from '@components/Loading'
import useCountryCode from '@hooks/useCountryCode'
import useExperiment from '@hooks/useExperiment.experiments'
import useIsDarkMode from '@hooks/useIsDarkMode'
import useInitialData from '@page-components/Layout/useInitialData'
import getEnv from '@providers/getEnv'

const env = getEnv()
const components_url =
  {
    local: 'http://components.internal:5140',
    dev: 'https://components.bejusto.com',
    prod: 'https://components.getjusto.com'
  }[env] || 'https://components.getjusto.com'

export default function ViteAddressIframe({onAbort, onConfirm, onStep}) {
  const countryCode = useCountryCode()
  const [loading, setLoading] = useState(true)
  const darkMode = useIsDarkMode()
  const {design} = useInitialData()
  const iframeRef = useRef(null)
  const awsOrGoogle = useExperiment('address-provider-aws-or-google', {allowServerSide: true})

  const componentUrl = `${components_url}/address-form?appIdentifier=websites&provider=${
    awsOrGoogle ? awsOrGoogle : 'google'
  }&countryCode=${countryCode}&darkMode=${darkMode}&primaryColor=${design?.design?.primaryColor?.replace(
    '#',
    ''
  )}`

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      console.log('Received message from iframe')
      if (event.data.type === 'resize') {
        console.log('Received resize message from iframe', event.data.height)
        iframeRef.current.style.height = event.data.height + 'px'
      }

      if (event.data.type === 'success') {
        console.log('Received success message from iframe', event.data.address)
        onConfirm(event.data.address)
      }

      if (event.data.type === 'failed') {
        console.log('Received failed message from iframe', event.data.error)
        onAbort(event.data.error)
      }

      if (event.data.type === 'step') {
        console.log('Received step message from iframe', event.data.step)
        onStep(event.data.step)
      }

      if (event.data.type === 'loaded') {
        // Prefered message instead of using the iframe load event because it consider internal iframe load events
        console.log('Received loaded message from iframe')

        setLoading(false)
      }
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])

  return (
    <div className="relative">
      {loading ? (
        <div className="absolute top-0 w-full h-full flex flex-row items-center justify-center bg-white dark:bg-zinc-800">
          <Loading />
        </div>
      ) : null}
      <iframe
        ref={iframeRef}
        title="V3 Address Form"
        className="w-full flex items-center justify-center"
        src={componentUrl}
        allow="geolocation"
        sandbox="allow-scripts allow-same-origin"
      />
    </div>
  )
}
