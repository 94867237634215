import React from 'react'

export type DeliveryWizardStep = 'choose' | 'create'
export type DeliveryWizardSubstep = 'search' | 'search-focus' | 'confirm' | 'change-preferred-store' | 'select'

export const DeliveryWizardStepContext = React.createContext<{
  step: DeliveryWizardStep
  setStep: (step: DeliveryWizardStep) => void
  substep: DeliveryWizardSubstep
  setSubstep: (substep: DeliveryWizardSubstep) => void
  onClose?: () => void
}>({
  step: 'choose',
  setStep: () => {},
  substep: 'search',
  setSubstep: () => {},
  onClose: () => {}
})

export default function useDeliveryWizardStep() {
  return React.useContext(DeliveryWizardStepContext)
}
