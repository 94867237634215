import useWebsiteId from '@hooks/useWebsiteId'
import {gql, useMutate} from 'apollo-hooks'

import {preferencesForAddressContext} from './fragments/preferencesForAddressContext.main'

export default function useSetPlaceIdInPreferences() {
  const websiteId = useWebsiteId()
  const mutate = useMutate()
  return (placeId: string) =>
    mutate({
      mutation: gql`
        mutation setPlaceIdInPreferences($websiteId: ID, $placeId: ID) {
          setUserPreferences(websiteId: $websiteId, placeId: $placeId) {
            _id
            ...preferencesForAddressContext
          }
        }
        ${preferencesForAddressContext}
      `,
      variables: {placeId, websiteId},
      refetchQueries: ['getUserAddresses']
    })
}
