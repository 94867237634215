import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export const getUserAddressesQuery = gql`
  query getUserAddresses {
    addresses {
      _id
      address
      addressLine2
      addressSecondary
      placeId
      comment
    }
  }
`

export default function useUserAddresses() {
  const {addresses, loading, error} = useQuery({
    query: getUserAddressesQuery
  })

  return {addresses, loading, error}
}
