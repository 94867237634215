import {gql, useMutate} from 'apollo-hooks'

export default function useClaimAddressMutate() {
  const mutate = useMutate()
  return (addressRefString: string) =>
    mutate({
      mutation: gql`
        mutation ClaimAddress($addressRefString: String!) {
          claimAddress(addressRefString: $addressRefString) {
            id
            provider
          }
        }
      `,
      variables: {addressRefString},
      clientName: 'addresses'
    })
}
