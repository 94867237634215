import {useState} from 'react'
import {IoMdPin, IoMdTrash} from 'react-icons/io'
import {MdMoreVert} from 'react-icons/md'
import classnames from '@helpers/misc/classnames'

import useDeliveryWizardStep from '../hooks/useDeliveryWizardStep'
import useUserAddresses from '../hooks/useUserAddresses.main'

export default function AddressesList({
  onSelect,
  onDelete
}: {
  onSelect: (address: any) => void
  onDelete: (address: any) => void
}) {
  const {addresses} = useUserAddresses()
  const {substep} = useDeliveryWizardStep()
  const [placeIdForDeletion, setPlaceIdForDeletion] = useState(null)
  const filteredAddresses = addresses?.reverse()
  return (
    <div className="relative">
      <div
        className={classnames(
          'overflow-y-scroll overflow-x-hidden transition-all',
          substep === 'search-focus' ? 'max-h-20' : 'max-h-72'
        )}>
        {filteredAddresses?.map((address, index) => (
          <div
            key={index}
            className="relative group hover:bg-gray-50 rounded-md w-full cursor-pointer dark:hover:bg-zinc-600">
            <div
              className={classnames(
                'flex flew-row flex-nowrap justify-between items-strech max-w-full w-full',
                placeIdForDeletion === address.placeId ? 'opacity-40' : 'opacity-100'
              )}>
              <div
                className="flex flew-row grow justify-start items-center p-2 min-w-0"
                onClick={() => {
                  if (placeIdForDeletion === address.placeId) return setPlaceIdForDeletion(null)
                  onSelect(address)
                }}>
                <IoMdPin className="w-8 flex-none" />
                <div className="flex flex-col grow overflow-clip justify-start text-left items-start min-w-0">
                  <p className="text-ellipsis overflow-hidden whitespace-nowrap font-semibold text-sm max-w-full">
                    {address?.address}
                    {address?.addressLine2 ? `, ${address?.addressLine2} ` : ''}
                  </p>
                  <p className="text-ellipsis overflow-hidden whitespace-nowrap font-normal text-xs text-zinc-500 dark:text-zinc-200 max-w-full">
                    {address?.addressSecondary}
                  </p>
                </div>
              </div>
              <div
                className="flex flex-none  hover:text-zinc-400 w-20 justify-center items-center self-stretch hover:bg-gray-100"
                onClick={() => {
                  setPlaceIdForDeletion(address.placeId)
                }}>
                <MdMoreVert className="p-2 w-8 h-8" />
              </div>
            </div>
            <button
              onClick={() => onDelete(address)}
              className={classnames(
                'absolute top-0 bg-rose-700 hover:bg-rose-600 h-full text-white text-xs p-5 font-bold w-32 transition-all',
                placeIdForDeletion === address.placeId ? 'right-0' : ' -right-32'
              )}>
              <IoMdTrash /> Eliminar
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
