import Button from '@components/V3/Button'
import logger from '@helpers/logger'
import useMessage from '@hooks/useMessage'
import {useTranslation} from 'next-i18next'
import {useState} from 'react'
import {IoIosWarning} from 'react-icons/io'
import {MdArrowBack} from 'react-icons/md'

import AddressCard from '../ChooseTimeAndPlace/Shared/AddressCard'
import useClaimAddressMutate from '../hooks/useClaimAddressMutation.address'
import useDeleteAddressMutation from '../hooks/useDeleteAddressMutation.main'
import useDeliveryWizardStep from '../hooks/useDeliveryWizardStep'
import useSetPlaceIdInPreferences from '../hooks/useSetPlaceIdInPreferences'

import AddressesList from './AddressesList'
import ViteAddressIframe from './ViteAddressIframe'

export default function AddressSelector({
  onChange,
  listOnly,
}: {onChange?: (place: any) => void; listOnly?: boolean}) {
  const setPlaceIdInPreferences = useSetPlaceIdInPreferences()
  const {setStep, substep, setSubstep} = useDeliveryWizardStep()
  const claimAddress = useClaimAddressMutate()
  const [confirmDeletion, setConfirmDeletion] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const deleteAddress = useDeleteAddressMutation()
  const showMessage = useMessage()
  const {t} = useTranslation('website', {keyPrefix: 'delivery.create'})

  return confirmDeletion ? (
    <AddressCard shadow={false}>
      <div className="flex flex-col divide-y dark:divide-gray-700 space-y-6">
        <div className="flex flex-col justify-center items-center">
          <IoIosWarning className="text-red-500 w-12 h-12" />
          <h2 className="text-center text-xl">{t('delete.title')}</h2>
        </div>
        <div className="flex flex-col text-center items-center justify-center pt-6">
          <p>{t('delete.description')}</p>
          <strong className="text-ellipsis overflow-hidden whitespace-nowrap min-w-0 max-w-full">
            {[confirmDeletion.address, confirmDeletion.addressSecondary]
              .filter(v => !!v)
              .join(', ')}
          </strong>
        </div>
        <div className="flex justify-between items-center pt-6">
          <Button
            danger
            loading={deleting}
            big
            onClick={() => {
              setDeleting(true)
              deleteAddress(confirmDeletion._id)
                .then(() => {
                  setConfirmDeletion(null)
                })
                .catch(error => {
                  console.log(error)
                  showMessage(error)
                })
                .finally(() => {
                  setDeleting(false)
                })
            }}
          >
            {t('delete.confirm')}
          </Button>
          <Button
            big
            onClick={() => {
              setConfirmDeletion(null)
            }}
          >
            {t('delete.cancel')}
          </Button>
        </div>
      </div>
    </AddressCard>
  ) : (
    <div className="flex flex-col gap-4">
      {substep !== 'confirm' && (
        <div>
          <AddressCard shadow={true}>
            {listOnly ? null : (
              <div className="flex items-center mb-2 mx-4 pb-4">
                <MdArrowBack
                  className="w-6 h-6 mr-4 cursor-pointer text-black hover:text-gray-700 dark:text-white dark:hover:text-gray-300"
                  onClick={() => setStep('choose')}
                />
                <h2 className=" text-lg">{t('title')}</h2>
              </div>
            )}
            <AddressesList
              onSelect={address => {
                setPlaceIdInPreferences(address.placeId).then(() => {
                  setStep('choose')
                  setSubstep('select')
                  onChange?.(address)
                })
              }}
              onDelete={setConfirmDeletion}
            />
          </AddressCard>
        </div>
      )}
      <ViteAddressIframe
        onAbort={error => {
          if (error) {
            showMessage(`Lo sentimos, ocurrió un error al guardar tu dirección: ${error.message}`)
            logger.error('ViteAddressIFrame abort with error', error)
          }
          setStep('create')
        }}
        onConfirm={newAddress => {
          if (newAddress) {
            const {placeId} = newAddress
            claimAddress(placeId)
              .then(() => {
                return setPlaceIdInPreferences(placeId)
              })
              .then(() => {
                setStep('choose')
                setSubstep('search')
                onChange?.(newAddress)
              })
              .catch(err => {
                showMessage(`Lo sentimos, ocurrió un error al guardar tu dirección: ${err.message}`)
                logger.error('Error claimAddress', err)
              })
          }
        }}
        onStep={setSubstep}
      />
    </div>
  )
}
