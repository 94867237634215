import {
  GetPreferencesForAddressContextQuery,
  GetPreferencesForAddressContextQueryVariables
} from '@data/__generated__/types.main'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import {preferencesForAddressContext} from './fragments/preferencesForAddressContext.main'

export default function usePreferencesForAddressContext() {
  const websiteId = useWebsiteId()

  const result = useQuery<
    GetPreferencesForAddressContextQuery,
    GetPreferencesForAddressContextQueryVariables
  >({
    query: gql`
      query getPreferencesForAddressContext($websiteId: ID) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          ...preferencesForAddressContext
        }
      }
      ${preferencesForAddressContext}
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first',
    // pollInterval: 1000 * 60 * 10,
    omit: isServerSide()
  })

  return {
    preferences: result.preferences,
    loading: result.loading
  }
}
