import {gql, useMutate} from 'apollo-hooks'

import usePreferencesForAddressContext from './usePreferencesForAddressContext.main'
import useSetPlaceIdInPreferences from './useSetPlaceIdInPreferences'

export default function useDeleteAddressMutation() {
  const {preferences} = usePreferencesForAddressContext()
  const setPlaceId = useSetPlaceIdInPreferences()

  const mutate = useMutate()
  return async (addressId: string) => {
    await mutate({
      mutation: gql`
        mutation deleteAddress($addressId: ID!) {
          deleteAddress(addressId: $addressId)
        }
      `,
      variables: {addressId},
      refetchQueries: ['getUserAddresses']
    })

    if (preferences.address?._id === addressId) {
      setPlaceId(null)
    }
  }
}
